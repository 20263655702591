<template>
  <router-view/>
</template>

<style lang="scss">
@font-face {
  font-family: MarkerFelt;
  src: url('./assets/font/MarkerFelt.ttf'),
}

@font-face {
  font-family: Schwifty;
  src: url('./assets/font/get_schwifty.ttf'),
}

html, body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // padding-top: 80px;
}
</style>
